<template>
  <div class="box selectable has-fullheight" :class="boxClass" @click="toggle">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "GoogleCalendar",

  props: {
    calendar: {
      type: Object,
      required: true,
    },

    currentValue: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      isChecked: false,
    };
  },

  computed: {
    text() {
      return this.calendar.summary;
    },

    boxClass() {
      if (this.isChecked) {
        return "selected";
      } else {
        return "";
      }
    },
  },

  methods: {
    toggle() {
      this.isChecked = !this.isChecked;
      this.$emit("input", this.isChecked);
    },
  },

  watch: {
    currentValue: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.isChecked = true;
        } else {
          this.isChecked = false;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-size: 14px;
  line-height: 130%;
  word-break: break-word;
  height: 100%;

  &:not(:last-child) {
    margin-bottom: 0 !important;
  }
}
</style>
